<template>
  <Transition name="site-nav">
    <nav v-if="menu" class="site-nav">
      <AtomsList v-if="social" :items="social" custom-class="site-social" />
      <AtomsList :items="items" custom-class="site-nav" @clicked="menu = false">
        <template #link>
          <Diamond class="site-nav-diamond" />
        </template>
      </AtomsList>
    </nav>
  </Transition>
</template>

<script lang="ts" setup>
import type { NavItem } from '~/types'
import Diamond from '~/assets/svg/diamond.svg'

const { menu } = useShared()

defineProps({
  social: {
    type: Array as () => NavItem[],
    default: undefined
  }
})

watch(useRoute(), () => (menu.value = false), { immediate: true })

useHead(() => ({
  htmlAttrs: {
    style: menu.value ? 'overflow: hidden' : '',
  },
}))

const { button } = useGroq

const query = groq`*[_type == "menu" && slug.current == $slug][0].items[] {
    ${button()},
    "options": {
      !('hideOnLarger' in options) => {
        "hide-on-desktop": 'hideOnDesktop' in options,
      },
      "hide-on-larger": 'hideOnLarger' in options,
      "hide-on-smaller": 'hideOnSmaller' in options,
      !('hideOnSmaller' in options) => {
        "hide-on-mobile": 'hideOnMobile' in options,
      },
    },
    children[] {
      ${button()},
      "options": {
        "hide-on-desktop": 'hideOnDesktop' in options,
        "hide-on-tablet": 'hideOnTablet' in options,
        "hide-on-mobile": 'hideOnMobile' in options,
      },
    }
}`
const { data: items, refresh } = useSanityQuery(query, { slug: 'main' })

onMounted(() => {
  if (!items) refresh()
})
</script>

<style lang="scss">
.site-nav {
  position: fixed;
  inset: 0;
  z-index: 800;
  height: 100%;
  display: flex;
  flex-flow: column;
  background: var(--bg);
  transition: transition($ts, (transform));

  padding-top: 10rem;

  &-enter-from,
  &-leave-to {
    transform: translateY(-100vh);
  }

  font-size: 1.2vmax;

  @include media('<desktop') {
    font-size: 1.2rem;
  }

  @include media('<tablet') {
    padding-top: 6rem;
  }

  .site-social {
    &-list {
      display: flex;
      min-width: 100%;
      justify-content: space-evenly;
      padding: $lg;

      @include media('>=tablet') {
        order: 1;
      }
    }
    &-link {
      padding: $md;
    }
  }

  .site-nav {
    &-list {
      padding-top: 12vh;
      display: flex;
      justify-content: space-evenly;
      overflow: hidden;
      align-items: flex-start;

      @include media('<desktop') {
        justify-content: flex-start;
        height: 100%;
        overflow: hidden scroll;
        margin: 0;
        padding: 0 0 4rem;
        flex-flow: column;
        align-items: center;
      }
    }
    &-diamond {
      margin-top: 0.5em;
      height: 1em;
      color: currentColor;
    }
    &-item {
      display: flex;
      flex-flow: column;
      align-items: center;
      font-family: var(--font-primary);
    }
    &-link {
      flex-flow: column;
      justify-content: center;
      padding-left: $rg;
      padding-right: $rg;
    }
    &-child {
      &-item {
        font-family: var(--font-secondary);
      }
    }
  }
}
</style>
